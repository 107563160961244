/* eslint-disable react/prop-types */
// @ts-check

import { lazy } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { NoSidebar } from './layout-blueprints/NoSidebar';
import RouteIfAuthenticated from './components/Authentication/RouteIfAuthenticated';
import { SlackConnect } from './components/Slack/Connect';
import { BlankPage } from './layout-blueprints/BlankPage';
import { DevPath, getRedirectToLink, Path, RedirectsFromPath } from './RoutePath';
import { EmbedPage } from './layout-blueprints/EmbedPage';
import { ViewComvisionHome } from './views/Home/Comvision';
import ViewHome from './views/Home/Home';
import { ViewComvisionHomeLanding } from './views/Home/ComvisionLanding';
import { App } from './App';
import RoutesAdminElement from './RoutesAdmin';
import RoutesAdminAnalyticsElement from './RoutesAdminAnalytics';
import RouteIfCondition from './components/Authentication/RouteIfCondition';
import ViewUnauthorized from './views/Forbidden/Unauthorized';

/** @import { RouteObject } from 'react-router-dom'; */

const ViewChannel = lazy(() => import('./views/Channel/Channel'));
const ViewChannelWatch = lazy(() => import('./views/Channel/Watch/Watch'));
const ViewChannelWatchEmbed = lazy(() => import('./views/Channel/Watch/WatchEmbed'));
const ViewChannelWatchEmbedTest = lazy(() => import('./views/Channel/Watch/WatchEmbedTest'));
const ViewChannelFrame = lazy(() => import('./views/Channel/Frame'));
const ViewChannelFrameTest = lazy(() => import('./views/Channel/FrameTest'));
const ViewCookiePolicy = lazy(() => import('./views/LegalNotice/CookiePolicy'));
const ViewSearch = lazy(() => import('./views/Search/Search'));
const ViewTermsAndPrivacy = lazy(() => import('./views/LegalNotice/TermsAndPrivacy'));
const ViewVod = lazy(() => import('./views/VodView/VodView'));
const ViewEmbedVod = lazy(() => import('./views/EmbedVod/EmbedVod'));
const Checkout = lazy(() => import('./views/Checkout/Checkout'));
const Donate = lazy(() => import('./views/Donate/Donate'));
const ViewStudio = lazy(() => import('./views/Studio/Studio'));
const ViewStudioElements = lazy(() => import('./views/Studio/StudioElementsWrapper'));
const ViewStudioActivity = lazy(() => import('./views/Studio/StudioActivity'));
const ViewStudioChat = lazy(() => import('./views/Studio/StudioChatWrapper'));
const ViewStudioParticipants = lazy(() => import('./views/Studio/StudioParticipantsWrapper'));
const ViewStudioTools = lazy(() => import('./views/Studio/StudioToolsWrapper'));
const ViewStudioTemplates = lazy(() => import('./views/Studio/StudioTemplatesWrapper'));
const ViewStudioInactive = lazy(() => import('./views/Studio/Inactive'));
const ViewParticipant = lazy(() => import('./views/Participant/Participant'));
const ViewOperator = lazy(() => import('./views/Operator/Operator'));
const ViewLive = lazy(() => import('./views/Live/Live'));
const ViewLiveFrame = lazy(() => import('./views/Live/Frame'));
const ViewMediaTest = lazy(() => import('./views/MediaTest/MediaTest'));
const ViewMediaTestWebrtc = lazy(() => import('./views/MediaTest/Webrtc'));
const ViewHelpCenter = lazy(() => import('./views/HelpCenter/HelpCenter'));
const ViewUserGuide = lazy(() => import('./views/UserGuide/UserGuide'));
const ViewSurvey = lazy(() => import('./views/Survey/Survey'));
const ViewStudioAttendanceSurvey = lazy(() => import('./components/Survey/Attendance/Attendance'));
const ViewPost = lazy(() => import('./views/PostPageView/PostPageView'));
const ViewWatchVideos = lazy(() => import('./views/WatchVideos/WatchVideos'));
const ViewMeetTheBees = lazy(() => import('./views/Meet/MeetTheBees'));
const EditBlog = lazy(() => import('./views/Blog/EditBlog'));
const Blog = lazy(() => import('./views/Blog/Blog'));
const ViewNewUserPage = lazy(() => import('./views/NewUser/NewUserPage'));
const ViewPricesPage = lazy(() => import('./views/Prices/PricesPage'));
const NewsletterUnsubscribe = lazy(() => import('./views/Newsletter/Unsubscribe'));
const Newsletter = lazy(() => import('./views/Newsletter/Newsletter'));
const ViewSocialNetworkLinkingRedirect = lazy(() => import('./views/SocialNetwork/LinkingRedirect'));

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';
const isComvision = import.meta.env.VITE_PROJECT === 'comvision';
const isDev = process.env.NODE_ENV === 'development';

/**
 * @typedef {{
 * 	path: string,
 * }} RedirectProps
 */
const Redirect = (
	/** @type {RedirectProps} */
	{ path },
) => {
	const params = useParams();
	return <Navigate to={getRedirectToLink(path, params)} replace />;
};

const RedirectRoutes = RedirectsFromPath.map((path) => ({
	path, element: <Redirect path={path} />,
}));

/** @type {RouteObject[]} */
export const routes = [
	{
		element: <App />,
		errorElement: <ViewUnauthorized />,
		children: [
			...RedirectRoutes,
			RoutesAdminElement,
			RoutesAdminAnalyticsElement,
			// No sidebar routes
			{
				element: <NoSidebar />,
				children: [
					isDev && { path: DevPath.CHANNEL_FRAME_TEST, element: <ViewChannelFrameTest /> },
					{ path: Path.BLOG_PREVIEW, element: <Blog /> },
					{ path: Path.LEGAL_COOKIES, element: <ViewCookiePolicy /> },
					{ path: Path.LEGAL_TERMS, element: <ViewTermsAndPrivacy /> },
					{ path: Path.MEDIATEST, element: <ViewMediaTest /> },
					{ path: Path.PRICES, element: <ViewPricesPage /> },
					{ path: Path.SEARCH, element: <ViewSearch /> },
					{ path: Path.SLACK_CONNECT, element: <SlackConnect /> },
					{ path: Path.WEBRTCTEST, element: <ViewMediaTestWebrtc /> },
					// Channel
					isDev && { path: DevPath.WATCH_LINK_EMBED_TEST, element: <ViewChannelWatchEmbedTest /> },
					{ path: Path.CHANNEL, element: <ViewChannel /> },
					{ path: Path.POST, element: <ViewPost /> },
					{ path: Path.VOD, element: <ViewVod /> },
					{ path: Path.WATCH_LINK, element: <ViewChannelWatch /> },
					// New user
					isBeeyou && { path: Path.NEWUSERINFO_ANCHOR, element: <ViewNewUserPage /> },
					isBeeyou && { path: Path.NEWUSERINFO, element: <ViewNewUserPage /> },
					// isBeeyou || isLoggedIn routes
					{
						element: <RouteIfCondition
							condition={({ isLoggedIn }) => isBeeyou || isLoggedIn}
						/>,
						children: [
							{ path: Path.HOME_WATCH_VIDEOS, element: <ViewWatchVideos /> },
							{ path: Path.HOME_MEET_BEES, element: <ViewMeetTheBees /> },
						],
					},
					// Help
					{ path: Path.HELP_ANCHOR, element: <ViewHelpCenter /> },
					{ path: Path.HELP_USERGUIDE, element: <ViewUserGuide /> },
					{ path: Path.HELP, element: <ViewHelpCenter /> },
					// No sidebar routes (logged in)
					{
						element: <RouteIfAuthenticated />,
						children: [
							{ path: Path.BLOG_EDIT, element: <EditBlog /> },
							{ path: Path.CHECKOUT, element: <Checkout /> },
							{ path: Path.DONATE, element: <Donate /> },
							{ path: Path.NEWSLETTER_UNSUBSCRIBE, element: <NewsletterUnsubscribe /> },
							{ path: Path.NEWSLETTER, element: <Newsletter /> },
						].filter((r) => !!r),
					},
				].filter((r) => !!r),
			},
			// Blank page routes
			{
				element: <BlankPage />,
				children: [
					{ path: Path.SURVEY_STUDIO_ATTENDANCE, element: <ViewStudioAttendanceSurvey /> },
					{ path: Path.SURVEY, element: <RouteIfAuthenticated element={<ViewSurvey />} /> },
					{ path: Path.SOCIAL_NETWORK_LINKING_REDIRECT,
						element: <ViewSocialNetworkLinkingRedirect /> },
				],
			},
			// Studio participant / viewer
			{
				element: <NoSidebar isStudioRoute noSearch />,
				children: [
					{ path: Path.STUDIO_PARTICIPANT, element: <ViewParticipant /> },
					{ path: Path.STUDIO_VIEWER, element: <ViewLive /> },
					{
						element: <RouteIfAuthenticated />,
						children: [
							{ path: Path.STUDIO, element: <ViewStudioInactive /> },
						],
					},
				],
			},
			// Studio elements
			{
				element: <RouteIfAuthenticated element={<BlankPage />} />,
				children: [
					{ path: Path.STUDIO_ACTIVITY, element: <ViewStudioActivity /> },
					{ path: Path.STUDIO_CHAT, element: <ViewStudioChat /> },
					{ path: Path.STUDIO_HOST_ELEMENTS, element: <ViewStudioElements /> },
					{ path: Path.STUDIO_PARTICIPANTS, element: <ViewStudioParticipants /> },
					{ path: Path.STUDIO_TEMPLATES, element: <ViewStudioTemplates /> },
					{ path: Path.STUDIO_TOOLS, element: <ViewStudioTools /> },
				],
			},
			// Studio controller
			{
				element: <RouteIfAuthenticated element={<NoSidebar isStudioRoute />} />,
				children: [
					{ path: Path.STUDIO_HOST, element: <ViewStudio /> },
					{ path: Path.STUDIO_OPERATOR, element: <ViewOperator /> },
				],
			},
			// Embed / frame routes
			{
				element: <EmbedPage />,
				children: [
					{ path: Path.CHANNEL_FRAME, element: <ViewChannelFrame /> },
					{ path: Path.EMBED_VOD, element: <ViewEmbedVod /> },
					{ path: Path.STUDIO_VIEWER_FRAME, element: <ViewLiveFrame /> },
					{ path: Path.WATCH_LINK_EMBED, element: <ViewChannelWatchEmbed /> },
				],
			},
			// Home routes
			{
				path: Path.HOME_ANCHOR,
				element: <RouteIfCondition
					condition={({ isLoggedIn }) => !isLoggedIn && isComvision}
					element={<ViewComvisionHome />}
					fallback={<NoSidebar><ViewHome /></NoSidebar>}
				/>,
			},
			{
				path: Path.HOME,
				element: <RouteIfCondition
					condition={({ isLoggedIn }) => !isLoggedIn && isComvision}
					element={<ViewComvisionHome />}
					fallback={<NoSidebar><ViewHome /></NoSidebar>}
				/>,
			},
			{
				path: Path.ROOT,
				element: <RouteIfCondition
					condition={({ isLoggedIn }) => !isLoggedIn && isComvision}
					element={<ViewComvisionHomeLanding />}
					fallback={<NoSidebar><ViewHome /></NoSidebar>}
				/>,
			},
		].filter((r) => !!r),
	},
];

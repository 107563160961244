/* eslint-disable react/prop-types */
// @ts-check

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getFileUrl, AVATAR_SIZE } from '../../lib/file';
import Hexagon from '../Hexagon/Hexagon';
import defaultAvatar from '../../images/default-avatar.png';
import { getChannelLink } from '../../RoutePath';

/**
 * @typedef {{
 * 	avatarUrl?: string,
 * 	hashtag?: string,
 * 	onClick?: () => void,
* 	small?: boolean,
 * }} ProfileAvatarProps
 */

/** @type {React.FC<ProfileAvatarProps>} */
export const ProfileAvatar = ({
	avatarUrl,
	hashtag,
	onClick,
	small = false,
}) => {
	const { t } = useTranslation();
	const userAvatar = avatarUrl
		? getFileUrl({ name: avatarUrl }, AVATAR_SIZE.width)
		: defaultAvatar;

	const profileImage = (
		<div className="avatar-icon-wrapper avatar-icon-md mr-3 mt-1">
			<Hexagon className={`avatar-icon ${small ? '-mini' : ''} shadow-none`}>
				<img alt={t('Profile.Avatar.avatar')} src={userAvatar} />
			</Hexagon>
		</div>
	);

	if (onClick) {
		return (
			<div className="cursor-pointer" onClick={onClick}>
				{profileImage}
			</div>
		);
	}

	return (
		<Link to={hashtag ? getChannelLink(hashtag) : '#'}>
			{profileImage}
		</Link>
	);
};

const { VITE_CONTENT_ENDPOINT } = import.meta.env;

const baseUrlContent = VITE_CONTENT_ENDPOINT;

export const getFileUrl = (file) => `${baseUrlContent}${file.name}`;

export const getCrossOriginFileUrl = (fileUrl) => {
	const parsedFileUrl = new URL(fileUrl);
	parsedFileUrl.searchParams.set('crossorigin', 'anonymous');
	return parsedFileUrl.href;
};

/* eslint-disable react/prop-types */
// @ts-check
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { PlayerControlsDisplayContext } from './Context';

/**
 * hook to display player controls
 * @param {{
 *  enabled?: boolean,
 *  hideTimeout?: number,
 *  target: string,
 * }} param0
 * @returns {{
 *  showControls: boolean,
 * }}
 */
export const useShowControlsTimeout = ({
	enabled,
	target,
	hideTimeout = 2500,
}) => {
	const [showControls, setShowControls] = useState(true);
	const showControlsAndEnabled = !enabled ? true : showControls;

	useEffect(() => {
		if (!enabled) return undefined;

		const targetElement = document.querySelector(target);
		if (!targetElement) return undefined;

		/** @type {undefined | ReturnType<typeof setTimeout>} */
		let timeout;

		const restartHideControlsTimeout = () => {
			if (timeout) {
				clearTimeout(timeout);
			}

			timeout = setTimeout(() => {
				setShowControls(false);
			}, hideTimeout);
		};

		const onActivity = () => {
			setShowControls(true);
			restartHideControlsTimeout();
		};

		// DISABLED BECAUSE OF CONFLICTING STATE WITH OTHER EVENTS
		// const onClick = () => {
		// 	console.log('onClick');
		// 	setShowControls((s) => !s);
		// 	restartHideControlsTimeout();
		// };

		targetElement.addEventListener('mousemove', onActivity);
		targetElement.addEventListener('click', onActivity);
		targetElement.addEventListener('keydown', onActivity);
		targetElement.addEventListener('touchstart', onActivity);
		targetElement.addEventListener('touchmove', onActivity);

		restartHideControlsTimeout();

		return () => {
			clearTimeout(timeout);

			targetElement.removeEventListener('mousemove', onActivity);
			targetElement.removeEventListener('click', onActivity);
			targetElement.removeEventListener('keydown', onActivity);
			targetElement.removeEventListener('touchstart', onActivity);
			targetElement.removeEventListener('touchmove', onActivity);
		};
	}, [enabled, hideTimeout, target]);

	const contextValue = useMemo(() => ({
		showControls: showControlsAndEnabled,
	}), [
		showControlsAndEnabled,
	]);

	return contextValue;
};

/**
 * @typedef {{
* 	children: React.ReactNode,
* 	enabled?: boolean,
* 	forceDisplay?: boolean,
* 	target?: string,
* }} PlayerControlsDisplayProvider
*/

/** @type {React.FC<PlayerControlsDisplayProvider>} */
export const PlayerControlsDisplayProvider = (
	{
		children,
		enabled = true,
		forceDisplay: forceDisplayProps = false,
		target = 'body',
	},
) => {
	// TODO : use the hook above,
	// and there's no need to have two state for show and hide
	const [showPlayerControls, setShowPlayerControls] = useState(false);
	const [forceDisplayState, setForceDisplayControls] = useState(false);

	useEffect(() => {
		if (!enabled) return undefined;

		const targetElement = document.querySelector(target);
		if (!targetElement) return undefined;

		/** @type {undefined | ReturnType<typeof setTimeout>} */
		let timeout;

		const toggleControls = () => {
			if (timeout) {
				clearTimeout(timeout);
			}

			setShowPlayerControls(true);

			timeout = setTimeout(() => {
				setShowPlayerControls(false);
			}, 2500);
		};

		targetElement.addEventListener('mousemove', toggleControls);
		targetElement.addEventListener('click', toggleControls);
		targetElement.addEventListener('keydown', toggleControls);
		targetElement.addEventListener('touchstart', toggleControls);
		targetElement.addEventListener('touchmove', toggleControls);

		toggleControls();

		return () => {
			clearTimeout(timeout);

			targetElement.removeEventListener('mousemove', toggleControls);
			targetElement.removeEventListener('click', toggleControls);
			targetElement.removeEventListener('keydown', toggleControls);
			targetElement.removeEventListener('touchstart', toggleControls);
			targetElement.removeEventListener('touchmove', toggleControls);
		};
	}, [enabled, target]);

	const contextValue = useMemo(() => ({
		setForceDisplayControls,
		showPlayerControls,
	}), [
		setForceDisplayControls,
		showPlayerControls,
	]);

	const showControls = showPlayerControls || forceDisplayProps || forceDisplayState;

	return (
		<PlayerControlsDisplayContext.Provider value={contextValue}>
			<div className={clsx(
				'layout-wrapper',
				{ showPlayerControls: enabled && showControls },
				{ hidePlayerControls: enabled && !showControls },
			)}
			>
				{children}
			</div>
		</PlayerControlsDisplayContext.Provider>
	);
};

PlayerControlsDisplayProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { v4 as uuidv4 } from 'uuid';

import { useScreenMode, ModeScreenLayout } from '../Studio/ScreenMode/Provider';
import * as apiChannelVideos from '../../api/channel/videos';
import * as apiPublicVideos from '../../api/public/video';
import { useTrackWatchTime } from './useTrackWatchTime';
import { VideoTimeline } from './VideoTimeline/VideoTimeline';
import { ShareVideoButton } from './ShareVideoButton';
import { ChannelButtonsPanelForVideo } from '../Channel/ChannelButtonsPanel/ForVideo';
import { useAuthentication } from '../Authentication/Authentication';
import { KEY_CODES } from './keyCodes';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { TransactionAdBlockType } from '../../lib/TransactionAdBlockType';
import { PlayerControlsDisplayProvider } from '../Player/ControlsDisplay/Provider';

import './HLSPlayer.scss';

const playerOptions = {
	preload: 'auto',
	fill: true,
	controlBar: false,
};

export const HLSPlayerVideoType = {
	LIVE: 'LIVE',
	VOD: 'VOD',
	AD: 'AD',
	VIDEO_CLIPPING: 'VIDEO_CLIPPING',
	VIDEO_THUMBNAIL: 'VIDEO_THUMBNAIL',
};

export const WatchedFromResourceType = {
	VOD: 'VOD',
	STUDIO: 'STUDIO',
};

export const HLSPlayer = ({
	adBlockInfo,
	ads,
	autoPlay,
	centerChildren,
	channel,
	children,
	className,
	clipTimecodes,
	controls,
	currentTime,
	currentVideoIndex,
	isLoop,
	isPlayerMuted,
	isPreview,
	isRequestingPlay,
	miniPlayer,
	nativeFullscreen,
	noFullscreen,
	onBufferedPercentChange,
	onEnded,
	onError,
	onProgressTimeChange,
	onTogglePlaying,
	onVideoLoaded,
	onVideoPlaying,
	source,
	src,
	videoId,
	videoPlayerRef,
	videoType,
	watchedFromResourceId,
	watchedFromResourceType,
	disableSharing,
}) => {
	const { isLoggedIn } = useAuthentication();
	const [videoSpeed, setVideoSpeed] = useState(1);
	const { currentModeScreen, toggleFullscreen } = useScreenMode();
	const viewedRef = useRef(false);
	const isVideoTool = [
		HLSPlayerVideoType.VIDEO_CLIPPING, HLSPlayerVideoType.VIDEO_THUMBNAIL,
	].includes(videoType);

	const currentVideoViewRef = useRef();
	const playerRef = useRef();
	const controlsRef = useRef();
	const videoJsNode = useRef();
	const watchId = useMemo(
		() => ((videoId || currentVideoIndex) ? uuidv4() : null),
		[videoId, currentVideoIndex],
	);
	const [progressTime, setProgressTime] = useState(0);
	const [bufferedPercent, setBufferedPercent] = useState(0);
	const [volume, setVolume] = useState(50);
	const [isMuted, setIsMuted] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	const requestAnimationRef = useRef();

	useEffect(() => {
		if (isPlaying && onVideoPlaying) onVideoPlaying();
	}, [isPlaying, onVideoPlaying]);

	useEffect(() => {
		if (isRequestingPlay && playerRef.current) {
			playerRef.current.play();
		}
	}, [isRequestingPlay]);

	const handleVideoJsRef = useCallback((node) => {
		if (node) {
			videoJsNode.current = node;
			if (videoPlayerRef) videoPlayerRef.current = node;
		}
	}, [videoPlayerRef]);

	const isProgressTimeInsideClipLimits = useCallback(
		(time) => !clipTimecodes || (clipTimecodes[0] <= time && time <= clipTimecodes[1]),
		[clipTimecodes],
	);

	const handleChangeProgressTime = useCallback(
		(newProgressTime) => {
			if (playerRef.current && isProgressTimeInsideClipLimits(newProgressTime)) {
				playerRef.current.currentTime(newProgressTime);
				setProgressTime(newProgressTime);
				if (onProgressTimeChange) onProgressTimeChange(newProgressTime);
			}
		},
		[isProgressTimeInsideClipLimits, onProgressTimeChange],
	);

	useEffect(() => {
		if (currentTime !== null) handleChangeProgressTime(currentTime);
	}, [currentTime, handleChangeProgressTime]);

	const handleTogglePlaying = useCallback(() => {
		if (!playerRef.current) return;
		if (isPlaying) {
			playerRef.current.pause();
		} else {
			playerRef.current.play();
		}
		setIsPlaying(!isPlaying);
	}, [isPlaying]);

	const handleChangeVolume = useCallback((value) => {
		setVolume(parseInt(value, 10));
		if (isMuted) {
			setIsMuted(false);
		}
	}, [isMuted]);

	const handleToggleSound = useCallback(() => {
		setIsMuted(!isMuted);
	}, [isMuted]);

	const viewVideo = useCallback(async (defaultVideoId = undefined, defaultWatchId = undefined) => {
		if (![HLSPlayerVideoType.VOD, HLSPlayerVideoType.AD].includes(videoType)) return;

		try {
			if (videoId) {
				const { data } = await (isLoggedIn ? apiChannelVideos : apiPublicVideos).viewVideo(
					defaultVideoId || videoId,
					defaultWatchId || watchId,
				);

				currentVideoViewRef.current = data?.videoView;
			}
		} catch (e) {
			// TODO: use debug
			// eslint-disable-next-line no-console
			console.error(e);
		}
	}, [isLoggedIn, videoType, videoId, watchId]);
	const viewVideoRef = useRef(viewVideo);

	const sendVideoWatchTime = useCallback(async (playStartTime, playEndTime) => {
		if (![HLSPlayerVideoType.VOD, HLSPlayerVideoType.AD].includes(videoType)) return;

		try {
			await (isLoggedIn ? apiChannelVideos : apiPublicVideos).sendVideoWatchTime({
				videoId,
				adBlockInfo,
				playEndTime,
				playStartTime,
				watchedFromResourceId,
				watchedFromResourceType,
				watchId,
			});
		} catch (e) {
			// TODO: use debug
			// eslint-disable-next-line no-console
			console.error(e);
		}
	}, [
		isLoggedIn,
		videoType,
		videoId,
		adBlockInfo,
		watchedFromResourceId,
		watchedFromResourceType,
		watchId,
	]);

	const { onPlay, onPause, onTimeUpdate } = useTrackWatchTime(playerRef, sendVideoWatchTime);
	const onPauseRef = useRef(onPause);
	const onPlayRef = useRef(onPlay);

	useEffect(() => {
		// console.log('videoSpeed', videoSpeed);
		if (playerRef.current) {
			playerRef.current.playbackRate(videoSpeed);
		}
	}, [videoSpeed]);

	const handleChangeFullscreen = useCallback(() => {
		if (noFullscreen) return;
		if (isVideoTool) return;

		if (nativeFullscreen) {
			if (document.fullscreenElement) {
				document.exitFullscreen();
			} else {
				playerRef.current.requestFullscreen();
			}
			return;
		}
		toggleFullscreen();
	}, [nativeFullscreen, noFullscreen, toggleFullscreen, isVideoTool]);

	const handleKeyDown = useCallback((event) => {
		const { keyCode } = event;
		event.preventDefault();
		event.stopPropagation();
		// f1 = 112, f2 = 113, f3 = 114 are already used for studio !
		if (keyCode === KEY_CODES.SPACE) handleTogglePlaying();
		if (keyCode === KEY_CODES.UP) handleChangeVolume(volume < 100 ? volume + 5 : volume);
		if (keyCode === KEY_CODES.DOWN) handleChangeVolume(volume > 0 ? volume - 5 : volume);
		if (keyCode === KEY_CODES.M) handleToggleSound();

		if (keyCode === KEY_CODES.END) handleChangeProgressTime(playerRef.current.duration());
		if (keyCode === KEY_CODES.HOME) handleChangeProgressTime(0);
		if (keyCode === KEY_CODES.RIGHT) {
			handleChangeProgressTime(playerRef.current.currentTime() + 0.5);
		}
		if (keyCode === KEY_CODES.LEFT) handleChangeProgressTime(playerRef.current.currentTime() - 0.5);

		if (keyCode === KEY_CODES.K) setVideoSpeed(1);
		if (keyCode === KEY_CODES.L) setVideoSpeed(videoSpeed < 8 ? videoSpeed * 2 : videoSpeed);
		if (keyCode === KEY_CODES.J) setVideoSpeed(videoSpeed > 0.5 ? videoSpeed / 2 : videoSpeed);

		if (keyCode === KEY_CODES.F) handleChangeFullscreen();
		// if ((keyCode === KEY_CODES.ESCAPE)
		//	&& (currentModeScreen === ModeScreenLayout.FULLSCREEN)) toggleFullscreen();
		// if (keyCode === KEY_CODES.C) console.log('c Enable/Disable closed captions');
		// if (keyCode === KEY_CODES.I) console.log('i in');
		// if (keyCode === KEY_CODES.O) console.log('o out');
		// if (keyCode === KEY_CODES.P) console.log('p point');
		// if (keyCode === KEY_CODES.T) console.log('t');
	}, [
		handleChangeFullscreen,
		handleChangeProgressTime,
		handleChangeVolume,
		handleTogglePlaying,
		handleToggleSound,
		videoSpeed,
		volume,
	]);

	useEffect(() => {
		if (videoJsNode.current) {
			const element = videoJsNode.current;

			element.addEventListener('keydown', handleKeyDown);
			element.addEventListener('dblclick', handleChangeFullscreen);

			return () => {
				element.removeEventListener('keydown', handleKeyDown);
				element.removeEventListener('dblclick', handleChangeFullscreen);
			};
		}
		return undefined;
	}, [handleKeyDown, handleChangeFullscreen]);

	useEffect(() => (
		// clean player on unmount
		() => {
			const player = playerRef.current;
			if (!player) return;
			// cleanUp(playerRef.current);
			setTimeout(() => { // Using setTimeout to fix:
				// DOMException: Failed to execute 'removeChild' on 'Node':
				// The node to be removed is not a child of this node
				player.dispose();
			}, 0);
		}
	), []);

	useEffect(() => {
		if (!playerRef.current) return;
		playerRef.current.src(src);
		viewedRef.current = false;
	}, [src, videoId, currentVideoIndex]);

	const playerInitialized = useRef(false);
	const handlersRef = useRef();
	const adsInfoRef = useRef();

	useEffect(() => {
		handlersRef.current = {
			onEnded,
			onError,
		};
	}, [ads, onEnded, onError]);

	useEffect(() => {
		if (ads && !adsInfoRef.current) {
			adsInfoRef.current = ads.map((ad) => ({
				...ad,
				isPlayed: false,
			}));
		}
	}, [ads]);

	useLayoutEffect(() => {
		onPauseRef.current = onPause;
		onPlayRef.current = onPlay;
		viewVideoRef.current = viewVideo;

		if (!playerInitialized.current) {
			playerInitialized.current = true;

			videojs(
				videoJsNode.current,
				{
					...playerOptions,
					autoplay: autoPlay,
					controls,
					crossOrigin: 'anonymous',
					loop: isLoop,
					muted: isPlayerMuted,
					playbackRates: [1, 2, 4, 8],
					userActions: {
						// hotkeys: true,
						doubleClick: false,
					},
				},
				function onPlayerReady() {
					playerRef.current = this;
					/* eslint-disable react/no-this-in-sfc */
					this.src(src);
					this.on('play', () => {
						if (onPlayRef.current) onPlayRef.current();
						if (onTogglePlaying) onTogglePlaying(true);
						setIsPlaying(true);
					});
					this.on('pause', () => {
						if (onPauseRef.current) onPauseRef.current();
						if (onTogglePlaying) onTogglePlaying(false);
						setIsPlaying(false);
					});
					this.on('loadedmetadata', () => {
						if (autoPlay) this.play();
						if (onVideoLoaded) onVideoLoaded();
					});
					this.on('ended', () => {
						if (handlersRef.current.onEnded) {
							handlersRef.current.onEnded();
							if (videoType === HLSPlayerVideoType.AD && currentVideoViewRef.current) {
								currentVideoViewRef.current = null;
							}
						}
					});
					this.on('error', (error) => {
						if (handlersRef.current.onError) {
							handlersRef.current.onError(error);
						}
					});

					this.on('timeupdate', () => {
						onTimeUpdate();
						setBufferedPercent(this.bufferedPercent());
						if (onBufferedPercentChange) onBufferedPercentChange(this.bufferedPercent());
						if (!viewedRef.current && this.currentTime() > 3) {
							viewedRef.current = true;
							viewVideoRef.current();
						}
					});
				},
			);
		}
	}, [
		ads,
		autoPlay,
		controls,
		isLoop,
		isPlayerMuted,
		isPlaying,
		onBufferedPercentChange,
		onEnded,
		onError,
		onPause,
		onPlay,
		onTimeUpdate,
		onTogglePlaying,
		onVideoLoaded,
		sendVideoWatchTime,
		src,
		videoId,
		videoType,
		viewVideo,
	]);

	useEffect(() => {
		if (!playerRef.current) return;
		if (isMuted) {
			playerRef.current.volume(0);
		} else {
			playerRef.current.volume(volume / 100);
		}
	}, [volume, isMuted]);

	useEffect(() => {
		if (!playerRef.current) return;
		const FPS = 30;
		let lastTimestamp = 0;
		if (isPlaying) {
			const animateProgressTimeLine = (time) => {
				requestAnimationRef.current = requestAnimationFrame(animateProgressTimeLine);
				if (time - lastTimestamp < 1000 / FPS) return;

				let newProgressTime = playerRef.current.currentTime();
				if (!isProgressTimeInsideClipLimits(newProgressTime)) {
					[newProgressTime] = clipTimecodes;
					playerRef.current.currentTime(newProgressTime);
				}
				setProgressTime(newProgressTime);
				if (onProgressTimeChange) onProgressTimeChange(newProgressTime);
				lastTimestamp = time;
			};
			requestAnimationRef.current = requestAnimationFrame(animateProgressTimeLine);
		} else {
			requestAnimationRef.current = cancelAnimationFrame(requestAnimationRef.current);
		}
		// eslint-disable-next-line consistent-return
		return () => {
			cancelAnimationFrame(requestAnimationRef.current);
		};
	}, [
		clipTimecodes,
		handleChangeProgressTime,
		isProgressTimeInsideClipLimits,
		isPlaying,
		onProgressTimeChange,
	]);

	return (
		<div className="d-flex flex-column min-h-0 h-100 w-100 position-relative" id="HLSPlayerContainer">
			<video-js
				class={clsx(
					'HLSPlayer video-js vjs-default-skin vjs-big-play-centered w-100 h-100 d-flex',
					{ 'vjs-full-height': !controls },
					{ isPlaying },
					className,
				)}
				controlBar={false}
				playsinline
				ref={handleVideoJsRef}
			/>
			{children}
			{controls && isPreview && (
				<PlayerControlsDisplayProvider
					enabled={videoType !== HLSPlayerVideoType.VIDEO_CLIPPING}
					forceDisplay={!isPlaying}
					target="#HLSPlayerContainer"
				>
					<div
						className={clsx(
							'ChannelButtonsPanelContainer',
							{ 'layout-fullscreen position-absolute zIndex-2 bottom-0 w-100 mw-100 mx-auto': videoType !== HLSPlayerVideoType.VIDEO_CLIPPING },
						)}
						ref={controlsRef}
					>
						<ChannelButtonsPanelForVideo
							className={clsx(videoType === HLSPlayerVideoType.VIDEO_CLIPPING ? 'bg-black' : 'HLSPlayer__Controls')}
							shareVideoButton={
								!disableSharing && source
								&& channel && videoType !== HLSPlayerVideoType.VIDEO_CLIPPING && (
									<ShareVideoButton
										channel={channel}
										miniPlayer={miniPlayer}
										video={source}
									/>
								)
							}
							centerChildren={(
								<>
									{videoType === HLSPlayerVideoType.VOD && (
										<VideoTimeline
											bufferedPercent={bufferedPercent}
											currentTime={progressTime}
											controlsRef={controlsRef}
											duration={playerRef.current?.duration()}
											onChangeProgressTime={handleChangeProgressTime}
											video={source}
										/>
									)}
									{centerChildren}
								</>
							)}
							handleChangeVolume={handleChangeVolume}
							// handleClickFullScreen={noFullscreen ? undefined : toggleFullscreen}
							handleClickFullScreen={videoType !== HLSPlayerVideoType.VIDEO_CLIPPING
								? handleChangeFullscreen
								: undefined}
							handleTogglePlaying={handleTogglePlaying}
							handleToggleSound={handleToggleSound}
							isFullscreen={currentModeScreen === ModeScreenLayout.FULLSCREEN}
							isMobilePortrait={false}
							isPlaying={isPlaying}
							isSoundMuted={isMuted}
							isVOD={videoType === HLSPlayerVideoType.VOD}
							isViewer
							volume={volume}
							miniPlayer={miniPlayer}
						/>
					</div>
				</PlayerControlsDisplayProvider>
			)}
		</div>
	);
};

HLSPlayer.propTypes = {
	adBlockInfo: PropTypes.shape({
		studioAd: PropTypes.string,
		studioRole: PropTypes.oneOf(Object.values(ResourceAccessRole)),
		type: PropTypes.oneOf(Object.values(TransactionAdBlockType)),
	}),
	ads: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		language: PropTypes.string,
		playPercentage: PropTypes.number,
		adVideo: PropTypes.string,
	})),
	autoPlay: PropTypes.bool,
	centerChildren: PropTypes.node,
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		hashtag: PropTypes.string.isRequired,
	}),
	children: PropTypes.node,
	className: PropTypes.string,
	clipTimecodes: PropTypes.arrayOf(PropTypes.number),
	controls: PropTypes.bool,
	currentTime: PropTypes.number,
	currentVideoIndex: PropTypes.number,
	isLoop: PropTypes.bool,
	isPlayerMuted: PropTypes.bool,
	isPreview: PropTypes.bool,
	isRequestingPlay: PropTypes.bool,
	miniPlayer: PropTypes.bool,
	nativeFullscreen: PropTypes.bool,
	noFullscreen: PropTypes.bool,
	onBufferedPercentChange: PropTypes.func,
	onEnded: PropTypes.func,
	onError: PropTypes.func,
	onTogglePlaying: PropTypes.func,
	onProgressTimeChange: PropTypes.func,
	onVideoLoaded: PropTypes.func,
	onVideoPlaying: PropTypes.func,
	source: PropTypes.shape({
		_id: PropTypes.string,
		duration: PropTypes.number,
		filename: PropTypes.string,
		playbackId: PropTypes.string,
		status: PropTypes.string,
		thumbnailTimeCode: PropTypes.number,
	}),
	src: PropTypes.string.isRequired,
	videoId: PropTypes.string,
	videoPlayerRef: PropTypes.shape({
		current: PropTypes.shape({}),
	}),
	videoType: PropTypes.oneOf(Object.values(HLSPlayerVideoType)),
	watchedFromResourceId: PropTypes.string,
	watchedFromResourceType: PropTypes.oneOf(Object.values(WatchedFromResourceType)),
	disableSharing: PropTypes.bool,
};

HLSPlayer.defaultProps = {
	adBlockInfo: undefined,
	ads: undefined,
	autoPlay: true,
	centerChildren: null,
	channel: undefined,
	children: undefined,
	className: '',
	clipTimecodes: undefined,
	controls: true,
	currentTime: null,
	currentVideoIndex: undefined,
	isLoop: false,
	isPlayerMuted: false,
	isPreview: true,
	isRequestingPlay: false,
	miniPlayer: false,
	nativeFullscreen: false,
	noFullscreen: false,
	onBufferedPercentChange: undefined,
	onEnded: undefined,
	onError: undefined,
	onProgressTimeChange: undefined,
	onTogglePlaying: undefined,
	onVideoLoaded: undefined,
	onVideoPlaying: undefined,
	source: undefined,
	videoId: undefined,
	videoPlayerRef: undefined,
	videoType: HLSPlayerVideoType.VOD,
	watchedFromResourceId: undefined,
	watchedFromResourceType: undefined,
	disableSharing: false,
};

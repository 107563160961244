import PropTypes from 'prop-types';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { getVideoSrc } from '../../../../../lib/file';
import { useAsyncErrorLog } from '../../../../../lib/hooks';
import { HLSPlayer, HLSPlayerVideoType } from '../../../../../components/HLSPlayer/HLSPlayer';
import { ScreenModeProvider } from '../../../../../components/Studio/ScreenMode/Provider';
import { isIOS } from '../../../../../lib/userAgent';
import * as publicVideoApi from '../../../../../api/public/video';

export const InlineVideo = ({ autoplay, video }) => {
	const { error, result: previewVideo } = useAsync(async () => {
		const { data } = await publicVideoApi.requestPublicVodById(video._id);
		return data;
	}, [video._id]);
	useAsyncErrorLog({ error });
	const { t } = useTranslation();

	const src = previewVideo && getVideoSrc(previewVideo);

	if (src && previewVideo?.accessStatus?.isAccessGranted) {
		return (
			<ScreenModeProvider>
				<div id={`vod-${previewVideo?._id}`}>
					<HLSPlayer
						autoPlay={autoplay}
						nativeFullscreen={isIOS}
						source={video}
						src={src}
						videoId={previewVideo?._id}
						videoType={HLSPlayerVideoType.VOD}
					/>
				</div>
			</ScreenModeProvider>
		);
	}
	return (
		<p className="mt-3 text-center">{t('PreviewVideo.loading')}</p>
	);
};

InlineVideo.propTypes = {
	autoplay: PropTypes.bool,
	video: PropTypes.shape({
		_id: PropTypes.string,
	}).isRequired,
};

InlineVideo.defaultProps = {
	autoplay: true,
};
